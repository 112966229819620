import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  Image,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Certificate,
  RefresherCategoryCourseContext,
} from "../@types/certificate.types";
import { Button } from "../components/buttons/Button";

import styles from "./certificate-viewer.styles";
import "../i18n";

export type CertificateProps = {
  firstName: string;
  lastName: string;
  orgName: string;
  courseTitle: string;
  completionDate: Date;
  creditedHours?: number;
  refresherCategories: RefresherCategoryCourseContext[] | null;
};

export type CertificateViewerProps = {
  certificate: Certificate;
  goBack: () => void;
};

const getFilename = (certificate: CertificateProps) => {
  const username =
    `${certificate.firstName?.[0] ?? ""}${certificate.lastName}`.toLowerCase();
  const month = certificate.completionDate.getMonth() + 1;
  const title = certificate.courseTitle
    .replace(/[^a-z0-9äöüß]+/gi, "-")
    .toLowerCase();
  const dateStr = `${certificate.completionDate.getFullYear()}-${month < 10 ? "0" : ""}${month}`;
  return `${title}-${dateStr}-${username.toLowerCase()}.pdf`;
};

const RefresherCategoryList: FC<{
  categories: RefresherCategoryCourseContext[];
}> = ({ categories }) => {
  const { t } = useTranslation();
  if (!categories) return null;
  const categoryNames = ["EDUCATIONAL", "PROFESSIONAL", "POLITICAL"] as const;
  const superscript = ["¹", "²", "³", "⁴", "⁵", "⁶", "⁷", "⁸", "⁹"];
  const categorySet = Object.fromEntries(
    categoryNames.map((c, i) => [
      c,
      { index: i, name: t(`certificates.refresherCategories.${c}`) },
    ]),
  );
  const childCourseList = categories.map((cat) => {
    const footnoteRefs = cat.categories
      .map((category) => categorySet[category].index)
      .sort()
      .map((index) => superscript[index])
      .join("");
    return (
      <View style={styles.refresherCourseList}>
        <Text key={cat.courseId} style={styles.refresherCourseText}>
          {cat.courseTitle}
          {footnoteRefs}
        </Text>
      </View>
    );
  });
  const legend = categoryNames.map((name, i) => {
    return (
      <>
        <Text key={name} style={styles.refresherLegendText}>
          {superscript[categorySet[name].index] ?? ""}
          {categorySet[name].name}
        </Text>
        {i < categoryNames.length - 1 && (
          <Text key={`sep-${i}`} style={styles.refresherLegendText}>
            {" "}
            |{" "}
          </Text>
        )}
      </>
    );
  });

  return (
    <View style={styles.refresherCategories}>
      {childCourseList}
      <Text style={styles.refresherCourseText}>{t`exam.title`}</Text>
      <View style={styles.refresherLegend}>{legend}</View>
    </View>
  );
};

export const CertificateDocument: FC<CertificateProps> = (props) => {
  const {
    firstName,
    lastName,
    orgName,
    courseTitle,
    completionDate,
    creditedHours,
    refresherCategories,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    // Disable hyphenation
    Font.registerHyphenationCallback((word) => [word]);
  }, []);

  return (
    <Document
      style={styles.document}
      title={getFilename(props)}
      author="Relias Learning Gmbh"
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.background}>
          <Image
            src={
              creditedHours ? "/certificate-rbp-bg.jpg" : "/certificate-bg.jpg"
            }
            style={styles.bgImage}
          />
        </View>
        <View style={styles.main}>
          <Text style={styles.certificateBelongsTo}>
            {t("certificates.thisCertificateAttestsThat")}
          </Text>
          <Text style={styles.learner}>
            {firstName} {lastName}
          </Text>
          <Text style={styles.orgName}>({orgName})</Text>
          <Text style={styles.forCourse}>{t("certificates.theCourse")}</Text>
          <Text style={styles.courseTitle}>{courseTitle}</Text>
          <Text style={styles.hasSuccessfullyCompleted}>
            {t("certificates.hasSuccessfullyCompleted")}
          </Text>
          <Text style={styles.completedOn}>
            {t("certificates.completedOn")}:{" "}
            {completionDate.toLocaleString("de-DE", {
              dateStyle: "medium",
              timeStyle: "short",
            })}
          </Text>
          {creditedHours && (
            <Text>
              {t("certificates.creditedHours")}: {creditedHours}
            </Text>
          )}
          {refresherCategories && (
            <RefresherCategoryList categories={refresherCategories} />
          )}
        </View>
      </Page>
    </Document>
  );
};

export const CertificateViewer: FC<CertificateViewerProps> = ({
  certificate,
  goBack,
}) => {
  const { t } = useTranslation();
  const props = {
    firstName: certificate.learner.learnerFirstName,
    lastName: certificate.learner.learnerLastName,
    orgName: certificate.learner.customers.name,
    courseTitle: certificate.courses.title,
    completionDate: new Date(certificate.completedOn),
    creditedHours: certificate.courses.rbp ?? undefined,
    refresherCategories: certificate.refresherCategories,
  };

  return (
    <div className="relative" style={{ height: "100%", width: "100%" }}>
      <div
        className="absolute top-8 left-4 right-4
        flex justify-center content-stretch gap-4 opacity-60 hover:opacity-100"
      >
        <Button
          className="my-0 mt-0 mb-0 border border-white"
          label={`❮ ${t("common.goBack")}`}
          action={goBack}
        />
        <PDFDownloadLink
          document={<CertificateDocument {...props} />}
          fileName={getFilename(props)}
        >
          <button
            className="cursor-pointer flex flex-row gap-2 text-sm
            font-semibold text-white justify-center content-center bg-gray-700 rounded-md
            px-4 py-2 hover:bg-gray-800 border border-white"
          >
            <img src="/download.svg" alt="" className="w-4 h-4 my-0.5" />
            <span>{t`certificates.download`}</span>
          </button>
        </PDFDownloadLink>
      </div>
      <PDFViewer style={{ height: "100%", width: "100%" }} showToolbar={false}>
        <CertificateDocument {...props} />
      </PDFViewer>
    </div>
  );
};

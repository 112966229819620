import { pdf } from "@react-pdf/renderer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Certificate } from "../../@types/certificate.types";
import { CertificateDocument } from "../certificate-viewer";

const devowelWord = (str: string) => {
  if (str.length <= 4) return str;
  const vowels = /[aeiouäöü]/gi;
  const startVowels = str.match(/^[aeiouäöü]*/i) || [""];
  const endVowels = str.match(/[aeiouäöü]*$/i) || [""];
  const middle = str
    .slice(startVowels[0].length, str.length - endVowels[0].length)
    .replaceAll(vowels, "");
  return [startVowels[0], middle, endVowels[0]].join("");
};
export const getAbbreviatedUsername = (cert: Certificate) => {
  return `${cert.learner.learnerFirstName.at(0)}${cert.learner.learnerLastName}`;
};

export const createFilename = (cert: Certificate) => {
  const username = getAbbreviatedUsername(cert);
  let words = cert.courses.title
    .replace(/^Pro\s+on\s+the\s+go\s+-/i, "POTG")
    .replace(" - ", " ")
    .split(" ");
  if (cert.courses.title.length > 30) {
    words = words.map((x) => devowelWord(x).trim());
  }
  const course = words.join(" ").substring(0, 60);
  const date = new Date(cert.completedOn)
    .toLocaleString("de-DE")
    .replaceAll(/[ .:]/g, "-")
    .replaceAll(",", "");
  return `${[course, date, username].join("-").replace(/[<>:"/\\|?* ]/g, "_")}.pdf`;
};

const useCertificatePdf = () => {
  const { t } = useTranslation();
  const [isDownloading, setLoading] = useState(false);
  const [downloadError, setError] = useState<string>();

  const generate = async (cert: Certificate) => {
    if (isDownloading) return;
    setError(undefined);
    setLoading(true);
    try {
      return pdf(
        <CertificateDocument
          firstName={cert.learner.learnerFirstName}
          lastName={cert.learner.learnerLastName}
          orgName={cert.learner.customers.name}
          courseTitle={cert.courses.title}
          completionDate={new Date(cert.completedOn)}
          refresherCategories={cert.refresherCategories}
        />,
      ).toBlob();
    } catch (e) {
      setError(JSON.stringify(e) ?? "Unknown error");
    } finally {
      setLoading(false);
    }
  };

  const download = async (cert: Certificate) => {
    const blob = await generate(cert);
    if (!blob) return;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = createFilename(cert);
    a.click();
  };

  return {
    generate,
    download,
    isDownloading,
    downloadError,
  };
};

export default useCertificatePdf;
